import { format } from "date-fns";
import { removeForbiddenCharacters } from "../Helper/Helper";

export default function TicketComponents(props) {
  const getImgeUrl = (img) => {
    return (
      "https://api.a2zticketing.com/" +
      props.eventImg.EventsID +
      "/" +
      removeForbiddenCharacters(img) +
      "/" +
      img
    );
  };

  return (
    <div className="table-responsive">
      <div class="new-ticket mb-3">
        <div class="left">
          <div className="ticker-img-wrapper">
            <img className="ticker-img" src={getImgeUrl(props.eventImg.EventPosterImage)} alt="img" />
            <p class="admit-one">
              <span>www.a2zticketing.com www.a2zticketing.com</span>
            </p>
            <div class="ticket-number">
              <p>#{props.ticketInfo.TicketNumberID}</p>
            </div>
          </div>
          <div class="ticket-info">
            <p class="date">
              {format(new Date(props.eventInfo.EventDate), "PPPP")}
            </p>
            <div class="show-name">
              <h1>#{props.ticketInfo.SeatTypes}</h1>
              <h2>{props.eventInfo.Title}</h2>
            </div>
            <div class="time">
              <p className="mb-0">
                Event time: {format(new Date(props.eventInfo.EventTime), "pp")}
              </p>
            </div>
            {/* <div class="tagline">
            <p>{props.eventInfo.EventCategoriesName}</p>
          </div> */}
            <p class="location">
              <span>{props.eventInfo.EventCategoriesName}</span>
              <span class="separator">
                <i class="fa-solid fa-cross"></i>
              </span>
              <span>{props.eventInfo.Location}</span>
            </p>
          </div>
        </div>
        <div class="right">
          <p class="admit-one">
            <span>www.a2zticketing.com www.a2zticketing.com</span>
          </p>
          <div class="right-info-container">
            <div class="show-name">
              <h1>#{props.ticketInfo.SeatTypes}</h1>
            </div>
            <div class="time">
              <p className="mb-1">{props.ticketInfo.EventsID}</p>
              <p className="mb-1">
                {format(new Date(props.eventInfo.EventTime), "pp")}
              </p>
            </div>
            <div class="barcode">
              <img
                src={
                  "https://api.a2zticketing.com/" + props.ticketInfo.QRCodeData
                }
                alt="QR code"
              />
              {/* <img src="https://i.imgur.com/DiBJvTA.png" alt="QR code" /> */}
            </div>
            <p class="ticket-number">#{props.ticketInfo.TicketNumberID}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
