import { useAuth } from "./Auth";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useIdleTimer } from "react-idle-timer";

export const RequireAuth = ({ allowedRoles, children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    logout();
    console.log("Do some idle action like log out your user", getElapsedTime());
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
    console.log("Do some active action: ", getRemainingTime());
  };

  const logout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    Cookies.remove("a2zUserToken");
    auth.logout();
    navigate("/login");
  };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 900,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  if (!auth.user) {
    return (
      <Navigate to="/login" replace={true} state={{ to: location.pathname }} />
    );
  }

  return allowedRoles.find(
    (role) => role === JSON.parse(auth.userProfile).UserCategory
  ) ? (
    children
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export const IsUserLogin = () => {
  const auth = useAuth();

  if (!auth.user || !auth.userProfile) {
    return false;
  }
  return true;
};

export const getUserType = () => {
  return JSON.parse(localStorage.getItem("userProfile")).UserCategory;
}
