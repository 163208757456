// api.js
import ApiBaseURL from "../../config/ApiBaseUrl";
import ApiList from "../../config/ApiList";
import Cookies from "js-cookie";

// Fetch Currency
export const fetchCurrency = async () => {
  const response = await ApiBaseURL.get(ApiList.apiGetCurrencyAll, {
    headers: {
      Authorization: Cookies.get("a2zUserToken"),
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
};

// Create a new Currency
export const createCurrency = async (currency) => {
  const response = await ApiBaseURL.post(
    ApiList.apiSaveMasterCurrency,
    currency,
    {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  return response.data;
};

// Update an existing Currency
export const updateCurrency = async (currency) => {
  const response = await ApiBaseURL.post(
    ApiList.apiSaveMasterCurrency,
    currency
  );
  return response.data;
};

// Delete a Currency
export const deleteCurrency = async (currencyId) => {
  const response = await ApiBaseURL.post(
    ApiList.apiSaveMasterCurrency,
    currencyId
  );
  return response.data;
};
