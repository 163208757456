import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiBaseURL from "../config/ApiBaseUrl";
import ApiList from "../config/ApiList";

const initialState = {
  userProfile: "",
};

export const submitLoginCredentials = createAsyncThunk(
  "CheckUserLogin",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.checkUserLogin, param, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const getJwtToken = createAsyncThunk("getJwtToken", async (param) => {
  const response = await ApiBaseURL.post(ApiList.jwtToken, param, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
});

export const jwtForget = createAsyncThunk("jwtForget", async (param) => {
  const response = await ApiBaseURL.post(ApiList.jwtForget, param, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
});

export const sendVerificationCodeToEmail = createAsyncThunk(
  "sendVerificationCodeToEmail",
  async (param) => {
    const response = await ApiBaseURL.post(
      ApiList.SendVerificationCode,
      param,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const verifyOTPCodeFromEmail = createAsyncThunk(
  "verifyOTPCode",
  async (code) => {
    const response = await ApiBaseURL.post(ApiList.compareEmailDigit, code, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.changePassword, param, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const newRegistration = createAsyncThunk(
  "newRegistration",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.saveNewRegistration, param, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const saveGuestRegistration = createAsyncThunk(
  "saveGuestRegistration",
  async (param) => {
    const response = await ApiBaseURL.post(
      ApiList.saveGuestRegistration,
      param,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [getJwtToken.fulfilled]: (state, { payload }) => {
      return { ...state, userProfile: JSON.stringify(payload.profile) };
    },
  },
});

export default authSlice.reducer;
