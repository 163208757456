import "./App.css";
import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Assets/css/style.css";
import "./Assets/scss/custom.scss";
import React, { lazy, useEffect } from "react";
import { Loader, Placeholder } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import AppRouter from "./routes";
import Cookies from "js-cookie";

function App() {
  useEffect(() => {
    let a2zLastActivity = JSON.parse(localStorage.getItem("a2zLastActivity"));

    if (!a2zLastActivity) {
      window.sessionStorage.clear();
      window.localStorage.clear();
      Cookies.remove("a2zUserToken");
    }

    if (a2zLastActivity) {
      let lastActiveDate = new Date(a2zLastActivity).getTime();
      let currentDate = new Date().getTime();

      if (Math.round((currentDate - lastActiveDate) / 60000) > 120) {
        // get value in minute. logout after 120 minutes.
        window.sessionStorage.clear();
        window.localStorage.clear();
        Cookies.remove("a2zUserToken");
      }
    }
  }, []);

  return (
    <>
      <div className="cm-loader" style={{ display: "none" }}>
        <Loader backdrop size="md" content="Loading..." vertical />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
      />
      <AppRouter />
    </>
  );
}

export default App;
