import { Loader } from "rsuite";

function HomeLoader(params) {
  return (
    <div className="cm-loader">
        <Loader backdrop size="md" content="Loading..." vertical />
      </div>
  );
}

export default HomeLoader;
