const forbiddenCharsForImg = [".jpg", ".png", ".webp", ".jpeg"];

export const removeForbiddenCharacters = (value) => {
  if (value) {
    for (let char of forbiddenCharsForImg) {
      value = value.split(char).join("");
    }
  }
  return value;
};

export const createOneToFiftyInteger = () => {
  const array = [];
  for (var input = 1; input <= 50; input++) {
    array.push(input);
  }
  return array;
};

export const getLoader = () => {
  return document.querySelector(".cm-loader");
};

export const showLoader = () => {
  return (document.querySelector(".cm-loader").style.display = "block");
};

export const hideLoader = () => {
  return setTimeout(function () {
    document.querySelector(".cm-loader").style.display = "none";
  }, 1000); //1 second
};

export const currencyFormat = (symbol, num) => {
  console.log("symbol: ", symbol);
  console.log("num: ", num);
  return symbol + " " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const isImageGreaterThan2MB = (img) => {
  return img.size > 1048576 * 2 ? true : false;
}

export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem('userProfile'));
}