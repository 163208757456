import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./actions/AuthSlice";
import EventReducer from "./actions/EventSlice";
import currencySlice from "./Redux/Slice/currencySlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    event: EventReducer,
    currencies: currencySlice
  },
});
