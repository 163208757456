import { toast } from "react-toastify";

export const showToast = (value) => {
  toast(value, {
    position: "top-right",
    autoClose: 3000,
    type: "warning",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastWarning = (value) => {
  toast(value, {
    position: "top-right",
    autoClose: 3000,
    type: "warning",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastSuccess = (value) => {
  toast(value, {
    position: "top-right",
    autoClose: 3000,
    type: "success",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastError = (value) => {
  toast(value, {
    position: "top-right",
    type: "error",
    autoClose: 5000,
    closeButton: true,
    closeOnClick: true,
    hideProgressBar: false,
    isLoading: false,
  });
};

export const toastLoading = (message) => {
  return toast.loading(message);
};

export const toastDismiss = () => {
  return toast.dismiss();
};

export const toastLoadingUpdate = (toastName, message) => {
  return toast.update(toastName, {
    render: message,
    type: "success",
    autoClose: 3000,
    closeButton: true,
    closeOnClick: true,
    hideProgressBar: false,
    isLoading: false,
  });
};

export const toastErrorLoadingUpdate = (toastName, message) => {
  return toast.update(toastName, {
    render: message,
    type: "error",
    autoClose: false,
    closeButton: true,
    closeOnClick: true,
    hideProgressBar: false,
    isLoading: false,
  });
};
