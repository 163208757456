// todosSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCurrency, createCurrency, updateCurrency, deleteCurrency } from '../Api/currencyApi'; // Import your API functions
import { toastError, toastSuccess } from '../../Helper/ToastifyHelper';

// Fetch Currency from the API
export const fetchCurrencyAsync = createAsyncThunk('currency/fetchCurrency', async () => {
  const response = await fetchCurrency();
  return response;
});

// Create a new Currency
export const createCurrencyAsync = createAsyncThunk('currency/createCurrency', async (formValue) => {
  const response = await createCurrency(formValue);
  toastMessage(response.status, response.result);
  return response;
});

// Update an existing Currency
export const updateTodoAsync = createAsyncThunk('todos/updateTodo', async (todo) => {
  const response = await updateCurrency(todo);
  return response.data;
});

// Delete a Currency
export const deleteTodoAsync = createAsyncThunk('todos/deleteTodo', async (todoId) => {
  await deleteCurrency(todoId);
  return todoId;
});

const todosSlice = createSlice({
  name: 'Currency',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencyAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateTodoAsync.fulfilled, (state, action) => {
        const updatedTodo = action.payload;
        const index = state.findIndex((todo) => todo.id === updatedTodo.id);
        if (index !== -1) {
          state[index] = updatedTodo;
        }
      })
      .addCase(deleteTodoAsync.fulfilled, (state, action) => {
        const todoId = action.payload;
        const index = state.findIndex((todo) => todo.id === todoId);
        if (index !== -1) {
          state.splice(index, 1);
        }
      });
  },
});

export default todosSlice.reducer;

function toastMessage(status, result) {
  if (status === "ok") {
    toastSuccess(result);
  } else {
    toastError(result);
  }
}
