import { useState, useContext, createContext, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem('userProfile') ? localStorage.getItem('userProfile') : null
  );

  const [userProfile, setUserProfile] = useState(
    localStorage.getItem('userProfile') ? localStorage.getItem('userProfile') : null
  );

  useEffect(() => {
    if (localStorage.getItem('userProfile')) {
      setUser(localStorage.getItem('userProfile'));
    }

    if (localStorage.getItem('userProfile')) {
      setUserProfile(localStorage.getItem('userProfile'));
    }
  }, []);

  const login = (user, userProfile) => {
    setUser(user);
    setUserProfile(userProfile);
  };

  const logout = () => {
    setUser(null);
    setUserProfile(null);
  };

  return (
    <AuthContext.Provider value={{ user, userProfile, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
