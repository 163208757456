import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { RequireAuth } from "../Components/auth/RequireAuth";
import { AuthProvider } from "../Components/auth/Auth";

// import 'bootstrap/dist/css/bootstrap.min.css';

// import HomeLayout from "../layout/HomeLayout";
// import MinimalLayout from "../layout/MinimalLayout";
// import "../views/home/css/style.css";
import HomeLoader from "../ui-components/loader/HomeLoader";
import TicketComponents from "../ui-components/TicketComponents";
const EditEvents = lazy(() => import("../views/event/EditEvents"));
const Currency = lazy(() => import("../views/settings/Currency"));
const RevenueDetails = lazy(() => import("../views/revenue/revenueDetails"));
const Revenue = lazy(() => import("../views/revenue"));
// import PrivacyPolicy from "../views/privacypolicy";

const Datadeletion = lazy(() => import("../views/datadeletion"));
const PrivacyPolicy = lazy(() => import("../views/privacyPolicy"));
const PaymentComplete = lazy(() => import("../views/paymentComplete"));
const LiveEventDetails = lazy(() => import("../views/home/LiveEventsDetails"));

const Profile = lazy(() => import("../views/profile"));
const PurchaseHistory = lazy(() => import("../views/purchaseHistory"));
const Enquiry = lazy(() => import("../views/enquiry"));
const Unauthorized = lazy(() => import("../Components/Unauthorized"));

const Dashboard = lazy(() => import("../views/Dashboard"));
const Home = lazy(() => import("../views/home"));
const Login = lazy(() => import("../views/Login"));
const MinimalLayout = lazy(() => import("../layout/MinimalLayout"));
const HomeLayout = lazy(() => import("../layout/HomeLayout"));
const MainLayout = lazy(() => import("../layout/MainLayout"));
const HostEvent = lazy(() => import("../views/event/HostEvent"));
const Events = lazy(() => import("../views/event/Events"));
const EventDetails = lazy(() => import("../views/event/EventDetails"));
const GeneratePin = lazy(() => import("../views/generatePin"));
const HomeEvent = lazy(() => import("../views/home/Event"));
const ForgetPassword = lazy(() => import("../views/home/ForgetPassword"));
const Cart = lazy(() => import("../views/home/pages/Cart"));
const UserRegister = lazy(() => import("../views/home/UserRegister"));
const GuestRegistration = lazy(() => import("../views/home/Registration"));
const EventCategories = lazy(() => import("../views/settings/EventCategories"));
const SeatType = lazy(() => import("../views/settings/SeatType"));
const UserType = lazy(() => import("../views/settings/UserType"));
const Country = lazy(() => import("../views/settings/Country"));

// const RequiredAuth = lazy(() => import("../Components/auth/RequiredAuth"));

const AppRouter = () => {
  return (
    <Suspense fallback={<HomeLoader />}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/unauthorized" exact element={<Unauthorized />} />
            <Route path="/privacypolicy" exact element={<PrivacyPolicy />} />
            <Route path="/datadeletion" exact element={<Datadeletion />} />
            <Route path="/ticket-sample" exact element={<TicketComponents />} />
            <Route
              exact
              path="/"
              element={
                <HomeLayout>
                  <Outlet />
                </HomeLayout>
              }
            >
              <Route exact index element={<Home />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route
                exact
                path="/payment-complete"
                element={<PaymentComplete />}
              />
              <Route
                exact
                path="/event/:id"
                element={
                  <RequireAuth
                    allowedRoles={[
                      "Admin",
                      "Event Hoster",
                      "Customer",
                      "Guest",
                    ]}
                  >
                    <HomeEvent />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/live-event/:id"
                element={<LiveEventDetails />}
              />
              <Route
                exact
                path="/cart"
                element={
                  <RequireAuth
                    allowedRoles={[
                      "Admin",
                      "Event Hoster",
                      "Customer",
                      "Guest",
                    ]}
                  >
                    <Cart />
                  </RequireAuth>
                }
              />
            </Route>

            <Route
              exact
              path="/login"
              element={
                <MinimalLayout>
                  <Outlet />
                </MinimalLayout>
              }
            >
              <Route exact index element={<Login />} />
              <Route exact path="/login/recover" element={<ForgetPassword />} />
              <Route
                exact
                path="/login/register/:type"
                element={<UserRegister />}
              />
              <Route
                exact
                path="/login/register/guest"
                element={<GuestRegistration />}
              />
            </Route>

            <Route
              exact
              path="/dashboard"
              element={
                <RequireAuth
                  allowedRoles={["Admin", "Event Hoster", "Customer"]}
                >
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/host-an-event"
              element={
                <RequireAuth
                  allowedRoles={["Admin", "Event Hoster"]}
                >
                  <MainLayout>
                    <HostEvent />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/event-revenue"
              element={
                <RequireAuth allowedRoles={["Admin", "Event Hoster"]}>
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </RequireAuth>
              }
            >
              <Route exact index element={<Revenue />} />
              <Route
                exact
                path="/event-revenue/:id"
                element={<RevenueDetails />}
              />
            </Route>

            <Route
              exact
              path="/events"
              element={
                <RequireAuth
                  allowedRoles={["Admin", "Event Hoster", "Customer"]}
                >
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </RequireAuth>
              }
            >
              <Route exact index element={<Events />} />
              <Route exact path="details/:id" element={<EventDetails />} />
            </Route>

            <Route
              exact
              path="/generate-pin"
              element={
                <RequireAuth
                  allowedRoles={["Admin", "Event Hoster", "Customer"]}
                >
                  <MainLayout>
                    <GeneratePin />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/edit-event/:id"
              element={
                <RequireAuth allowedRoles={["Admin", "Event Hoster"]}>
                  <MainLayout>
                    <EditEvents />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/view-tickets/:id"
              element={<PurchaseHistory />}
            />

            <Route
              exact
              path="/general-enquiry"
              element={
                <RequireAuth allowedRoles={["Admin"]}>
                  <MainLayout>
                    <Enquiry />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/profile"
              element={
                <RequireAuth
                  allowedRoles={["Admin", "Event Hoster", "Customer"]}
                >
                  <MainLayout>
                    <Profile />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/purchase-history"
              element={
                <RequireAuth
                  allowedRoles={["Admin", "Event Hoster", "Customer"]}
                >
                  <MainLayout>
                    <PurchaseHistory />
                  </MainLayout>
                </RequireAuth>
              }
            />

            <Route
              exact
              path="/settings"
              element={
                <RequireAuth allowedRoles={["Admin"]}>
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </RequireAuth>
              }
            >
              <Route
                exact
                path="/settings/event-categories"
                element={<EventCategories />}
              />
              <Route exact path="/settings/seat-type" element={<SeatType />} />
              <Route exact path="/settings/user-type" element={<UserType />} />
              <Route exact path="/settings/country" element={<Country />} />
              <Route exact path="/settings/currency" element={<Currency />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </Suspense>
  );
};

export default AppRouter;

function NotFound() {
  return <h2>Not Found</h2>;
}

function Contact() {
  return <h2>Contact</h2>;
}

function Loader() {
  return "this is loader";
}
