import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiBaseURL from "../config/ApiBaseUrl";
import ApiList from "../config/ApiList";
import Cookies from "js-cookie";

const initialState = {
  eventCategories: "",
  seatTypes: "",
  hostEvents: "",
  eventDetails: "",
  eventsList: "",
  invoiceClientsList: "",
  invoice: "",
  soldTickets: "",
  remainingTickets: "",
};

export const fetchEventCategories = createAsyncThunk(
  "EventCategories",
  async () => {
    const response = await ApiBaseURL.get(ApiList.getActiveEventCategories, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const fetchActiveSeatTypes = createAsyncThunk(
  "EventActiveSeatTypes",
  async () => {
    const response = await ApiBaseURL.get(ApiList.getActiveSeatTypes, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const fetchHostEvent = createAsyncThunk("GetHostEvent", async () => {
  const response = await ApiBaseURL.get(ApiList.getHostEvent, {
    headers: {
      Authorization: Cookies.get("a2zUserToken"),
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
});

export const fetchEventRevenueDetails = createAsyncThunk(
  "fetchEventRevenueDetails",
  async (params) => {
    const response = await ApiBaseURL.get(
      ApiList.getEventRevenueDetails + "/" + params,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const fetchEventsList = createAsyncThunk("EventsList", async (url) => {
  const response = await ApiBaseURL.get(url, {
    headers: {
      Authorization: Cookies.get("a2zUserToken"),
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
});

export const fetchHostEventDetails = createAsyncThunk(
  "GetEventDetails",
  async (param) => {
    const response = await ApiBaseURL.get(
      ApiList.getEventDetails + "/" + param,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const fetchInvoiceClientsList = createAsyncThunk(
  "getInvoiceClientsList",
  async (param) => {
    const response = await ApiBaseURL.get(
      ApiList.getInvoiceClientsList + "?EventsID=&UserID=" + param,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const fetchInvoice = createAsyncThunk("getInvoice", async (param) => {
  const response = await ApiBaseURL.get(ApiList.getInvoice + "/" + param, {
    headers: {
      Authorization: Cookies.get("a2zUserToken"),
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
});

export const GetEventTicketsCheckedIn = createAsyncThunk(
  "Get Event Tickets CheckedIn",
  async (param) => {
    const response = await ApiBaseURL.get(
      ApiList.apiGetEventTicketsCheckedIn + "?EventsID=" + param,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const saveEventDetails = createAsyncThunk(
  "SaveEventDetails",
  async (param) => {
    const response = await ApiBaseURL.post("/api/saveEventsDetails", param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const editEventDetails = createAsyncThunk(
  "editEventDetails",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.editEventDetails, param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const editEventImage = createAsyncThunk(
  "editEventImage",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.editEventImage, param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const editEventSeats = createAsyncThunk(
  "editEventSeats",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.editEventSeats, param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

// editEventImage

//editEventSeats

export const SendTicketEmail = createAsyncThunk(
  "Send Ticket Email",
  async (param) => {
    const response = await ApiBaseURL.post(
      ApiList.apiResendTicketEmail,
      param,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const uploadEventImageFile = createAsyncThunk(
  "UploadEventImageFile",
  async (param) => {
    console.log(param);
    const response = await ApiBaseURL.post(
      ApiList.uploadEventImageFile +
        "/" +
        param.id,
        param.data,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
);

export const saveInvoiceClientsDetails = createAsyncThunk(
  "saveInvoiceClientsDetails",
  async (param) => {
    const response = await ApiBaseURL.post(
      ApiList.saveInvoiceClientsDetails,
      param,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const fetchSoldTickets = createAsyncThunk(
  "fetchSoldTickets",
  async (id) => {
    const response = await ApiBaseURL.get(ApiList.getSoldTickets + "/" + id, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const fetchRemainingTickets = createAsyncThunk(
  "fetchRemainingTickets",
  async (params) => {
    const response = await ApiBaseURL.get(
      ApiList.getRemainingTickets + "/" + params,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const getActiveCountry = createAsyncThunk(
  "get Active Country",
  async (params) => {
    const response = await ApiBaseURL.get(ApiList.apiGetActiveCountry, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const getActiveCurrency = createAsyncThunk(
  "get Active Currency",
  async (params) => {
    const response = await ApiBaseURL.get(ApiList.apiGetActiveCurrency, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const getInvoiceClientsPaymentList = createAsyncThunk(
  "get Invoice Clients PaymentList",
  async (params) => {
    const response = await ApiBaseURL.get(
      ApiList.getInvoiceClientsPaymentList + "?EventsID=" + params,
      {
        headers: {
          Authorization: Cookies.get("a2zUserToken"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.data;
  }
);

export const approveEvents = createAsyncThunk(
  "approveEvents",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.approveEvents, param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const requestForModification = createAsyncThunk(
  "requestForModification",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.eventModification, param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const saveClientsOrders = createAsyncThunk(
  "saveClientsOrders",
  async (param) => {
    const response = await ApiBaseURL.post(ApiList.saveClientsOrders, param, {
      headers: {
        Authorization: Cookies.get("a2zUserToken"),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchEventCategories.fulfilled]: (state, { payload }) => {
      return { ...state, eventCategories: payload.result };
    },
    [fetchActiveSeatTypes.fulfilled]: (state, { payload }) => {
      return { ...state, seatTypes: payload.result };
    },
    [fetchHostEvent.fulfilled]: (state, { payload }) => {
      return { ...state, hostEvents: payload.result };
    },
    [fetchHostEventDetails.fulfilled]: (state, { payload }) => {
      return { ...state, eventDetails: payload };
    },
    [fetchEventRevenueDetails.fulfilled]: (state, { payload }) => {
      return { ...state, eventDetails: payload };
    },
    [fetchEventsList.fulfilled]: (state, { payload }) => {
      return { ...state, eventsList: payload };
    },
    [fetchInvoiceClientsList.fulfilled]: (state, { payload }) => {
      return { ...state, invoiceClientsList: payload };
    },
    [fetchInvoice.fulfilled]: (state, { payload }) => {
      return { ...state, invoice: payload };
    },
    [fetchSoldTickets.fulfilled]: (state, { payload }) => {
      return { ...state, soldTickets: payload };
    },
    [fetchRemainingTickets.fulfilled]: (state, { payload }) => {
      return { ...state, remainingTickets: payload };
    },
  },
});

export const getEventCategories = (state) => state.event.eventCategories;
export const getActiveSeatTypes = (state) => state.event.seatTypes;
export const getHostEvent = (state) => state.event.hostEvents;
export const getHostEventDetails = (state) => state.event.eventDetails;
export const getEventsList = (state) => state.event.eventsList;
export const getInvoiceClientsList = (state) => state.event.invoiceClientsList;
export const getInvoice = (state) => state.event.invoice;
export const getSoldTickets = (state) => state.event.soldTickets;
export const getRemainingTickets = (state) => state.event.remainingTickets;

export default authSlice.reducer;
